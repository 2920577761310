<template>
  <div>
    <span :class="{ 'font-weight-bold': !requirementFulfilled }"
      >{{ title }}
      <template v-if="!requirementFulfilled"
        ><b-icon
          icon="asterisk"
          class="span-required"
          v-b-tooltip.hover.v-secondary.noninteractive="
            'Debe seleccionar al menos un elemento.'
          "
          >*</b-icon
        ></template
      >
      <template v-else-if="required"
        ><b-icon icon="check" variant="success" scale="1.5"></b-icon
      ></template>
    </span>
    <v-select
      ref="vselect"
      v-model="current_selected"
      :options="inputOptions"
      :get-option-label="label_function"
      :label="label_field"
      :disabled="disabled"
      :required="required"
      @option:selected="slotOptionSelected"
    >
      <template #no-options> Nada para seleccionar. </template>
      <template v-if="cite_field" #option="option">
        <b>{{ option[label_field] }}</b>
        <br />
        <cite style="font-size: small">{{ option[cite_field] }}</cite>
      </template>
    </v-select>
    <b-list-group
      class="small list-group"
      v-if="show_list_"
      v-bind:style="{ 'max-height': list_items_show * 33 + 'px' }"
    >
      <b-list-group-item
        class="d-flex justify-content-between align-items-center py-0"
        v-for="item in selected_items"
        :key="item[id_field]"
        :disabled="disabled"
        >{{ item[label_field]
        }}<b-button
          variant="outline-danger"
          size="sm"
          class="round p-0 my-1"
          @click="slotRemoveItem(item)"
          ><b-icon icon="x"></b-icon
        ></b-button>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "TagSelector",
  components: {
    vSelect,
  },
  props: {
    title: {
      type: String,
    },
    options: {
      type: Array,
    },
    id_field: {
      type: String,
      default: "id",
    },
    label_field: {
      type: String,
      default: "name",
    },
    label_function: {
      type: Function,
    },
    cite_field: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    max: {
      /** @type {String}
       *  @options {[Number, 'all']}
       *  @description {Limita el numero de elementos que se podran seleccionar.}
       */
      type: [String, Number],
      default: "all",
    },
    show_list: {
      type: Boolean,
      default: true,
    },
    list_items_show: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clear_filters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_items: [], // Contiene los elementos que se muestran en el listado.
      current_selected: [], // No se usa, solo almacena el valor del selector.
      max_: 1, // Define el máximo de items a seleccionar.
      show_all: true, //Derfine si se muestra la opción --TODOS--  en el selector.
      multiple_: this.multiple, // Define si se pueden seleccionar varias opciones en el selector.
      show_list_: this.show_list, // Define si se mostrará el componente lista.
    };
  },
  computed: {
    inputOptions() {
      /**
       * Retorna las opciones a mostrar en el selector filtrando las que ya se encuentran en el componente Lista.
       * Añada la opción --TODOS-- si es necesario.
       * @returns {Array} Retorna las opciones del selector.
       */
      let items = [];
      if (
        this.show_all &&
        this.options.length != this.selected_items.length &&
        this.options.length > 1
      ) {
        let tmp = {};
        tmp[this.id_field] = "all";
        tmp[this.label_field] = "-- TODOS --";
        items.push(tmp);
      }
      this.options.forEach((option) => {
        if (
          !this.selected_items.find(
            (x) => x[this.id_field] == option[this.id_field]
          )
        ) {
          items.push(option);
        }
      });
      return items;
    },
    requirementFulfilled() {
      /**
       * Revisa si el componente cumple con los requisitos requeridos.
       * @returns {Boolean}
       */
      if (!this.required) return true;
      if (this.selected_items.length > 0) return true;
      return false;
    },
  },
  methods: {
    slotOptionSelected(option) {
      /**
       * Coloca los elementos seleccionados en el componente Lista.
       * Luego limpia el selector y emite la señal 'input' para actualizar el v-model.
       */
      if (option[this.id_field] == "all") {
        this.selected_items = [...this.options];
      } else if ((this.selected_items.length == this.max_) == 1) {
        this.selected_items = [];
        this.selected_items.push(option);
      } else if (this.selected_items.length == this.max_) {
        return;
      } else {
        this.selected_items.push(option);
      }
      this.$emit("input", this.selected_items);
      this.$refs.vselect.clearSelection();
    },
    slotRemoveItem(item) {
      /**
       * Elimina un elemento del componente Lista.
       * Luego emite la señal 'input' para actualizar el v-model.
       * @param {Object} item Espera un objeto para ser deseleccionado.
       */
      let index = this.selected_items.findIndex(
        (x) => x[this.id_field] == item[this.id_field]
      );
      if (index != -1) {
        this.selected_items.splice(index, 1);
        this.$emit("input", this.selected_items);
      }
    },
  },
  watch: {
    max() {
      if (this.max == "all") {
        this.multiple_ = true;
        this.max_ = this.options.length;
        this.show_all = true;
      } else {
        this.max_ = Number(this.max);
        this.show_all = false;
      }
      if (this.max_ == 1) {
        this.multiple_ = false;
        this.show_all = false;
      }
    },
    options() {
      let tmp = [];
      this.selected_items.forEach((item) => {
        let index = this.options.findIndex(
          (option) => option[this.id_field] == item[this.id_field]
        );
        if (index != -1) {
          tmp.push(item);
        }
      });
      this.selected_items = tmp;
      if (this.options.length == 1) {
        this.selected_items = [this.options[0]];
      }
      this.$emit("input", this.selected_items);
    },
    clear_filters() {
      this.selected_items = [];
    },
  },
  created() {},
};
</script>

<style scoped>
.list-group {
  max-height: 150px;
  margin-bottom: 10px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.span-required {
  font-weight: bold;
  font-size: 6pt;
  transform: translateY(-80%);
  color: red;
}
</style>